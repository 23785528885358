import React from 'react'
// import PropTypes from 'prop-types'
import MuiMenu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'


const Menu = ({...props}) => (
        <MuiMenu  {...props} />
    )

Menu.propTypes = {

}

Menu.Item = MenuItem;

export default Menu
